.svgItem {
  width: 100%;
  height: 100%;
}

.svgItem.filled {
  fill: #ffffff;
}

.svgItem:hover {
  transform: scale(1.2);
}

.disableHover > svg {
  transform: none !important;
}
