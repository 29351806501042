@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-6xl;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-xl;
  }
}

@layer utilities {
  .text-shadow-subtle {
    text-shadow: 0 1px 1px rgb(0 0 0 / 25%);
  }

  .container-padded {
    @apply container;
    @apply px-5;
  }

  .w-max-content {
    width: max-content;
  }

  .h-fit-screen {
    min-height: calc(100vh - 474px);
  }

  @screen xl {
    .h-fit-screen {
      min-height: calc(100vh - 388px);
    }
  }
}

body {
  font-family:
    'Noah',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  @apply bg-black;
  @apply text-white;
}

.plyr {
  --plyr-color-main: #fd5794;
}

.with-embedded-plyr .plyr {
  width: 100% !important;
  height: 100% !important;
}

.align-items-center {
    align-items: center;
}

.entity-reference-item .small-caps {
  font-variant: small-caps;
}

.entity-reference-item .italic {
  font-style: italic;
}

@media screen and (max-width: 460px) {
  .plyr__progress {
    display: none;
  }
}

.cky-consent-container{
    top: 0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.cky-consent-container .cky-consent-bar,
.cky-consent-container .cky-preference-wrapper{
    width:100%;
    max-width: 80vw;
    height: min-content;
}

body.with-embedded-video .cky-consent-container,
body.with-embedded-video .cky-btn-revisit-wrapper{
    display: none;
}

body.with-embedded-video .video-player-wrapper {
    --header-size: 0;
}
