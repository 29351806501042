@font-face {
  font-family: 'Noah';
  src:
    url('/fonts/Noah-Regular.woff2') format('woff2'),
    url('/fonts/Noah-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noah';
  src:
    url('/fonts/Noah-Bold.woff2') format('woff2'),
    url('/fonts/Noah-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src:
    url('/fonts/Gilroy-ExtraBold.otf') format('woff2'),
    url('/fonts/Gilroy-ExtraBold.otf') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src:
    url('/fonts/Gilroy-Light.otf') format('woff2'),
    url('/fonts/Gilroy-Light.otf') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-Black.ttf') format('woff2'),
    url('/fonts/Roboto-Black.ttf') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-BlackItalic.ttf') format('woff2'),
    url('/fonts/Roboto-BlackItalic.ttf') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-Bold.ttf') format('woff2'),
    url('/fonts/Roboto-Bold.ttf') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-BoldItalic.ttf') format('woff2'),
    url('/fonts/Roboto-BoldItalic.ttf') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-Regular.ttf') format('woff2'),
    url('/fonts/Roboto-Regular.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-Italic.ttf') format('woff2'),
    url('/fonts/Roboto-Italic.ttf') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-Light.ttf') format('woff2'),
    url('/fonts/Roboto-Light.ttf') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-LightItalic.ttf') format('woff2'),
    url('/fonts/Roboto-LightItalic.ttf') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-Medium.ttf') format('woff2'),
    url('/fonts/Roboto-Medium.ttf') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-MediumItalic.ttf') format('woff2'),
    url('/fonts/Roboto-MediumItalic.ttf') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-Thin.ttf') format('woff2'),
    url('/fonts/Roboto-Thin.ttf') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('/fonts/Roboto-ThinItalic.ttf') format('woff2'),
    url('/fonts/Roboto-ThinItalic.ttf') format('woff');
  font-weight: 100;
  font-style: italic;
}
