.spin {
  animation-name: spin;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }

  30% {
    transform: rotate(300deg) scale(1.1);
  }

  60% {
    transform: rotate(720deg) scale(0.9);
  }

  100% {
    transform: rotate(1080deg) scale(1);
  }
}
